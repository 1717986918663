import { StatusCodes } from "http-status-codes";

import { VendorAsyncDispatch } from "@vendor-app/app/_state/typedVendorRedux";

export const vendorAsyncDispatch: VendorAsyncDispatch = async function vendorAsyncDispatch(
  promise,
  onLoading,
  onError,
  onSuccess
) {
  // this must complete before proceeding
  await onLoading();

  try {
    const res = await promise; // not handling failures because that should be impossible per
    switch (res.type) {
      case "error": {
        return onError({
          status: res.status,
          message: res.message,
        });
      }
      case "success": {
        return onSuccess(res.body);
      }
    }
  } catch (error) {
    return onError({
      status: StatusCodes.BAD_REQUEST,
      message: `unhandled error: ${error?.message || ""}`,
    });
  }
};
